<template>
  <div class="daily_transaction">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;每日成交</a>
    </div>
    <div class="list">
      <div class="gameSelect">
        <el-select :popper-append-to-body="false" v-model="value" placeholder="请选择" @change="selectChange">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}" ></el-option>
        </el-select>
      </div>
      <div class="content">
        <div class="daily_transaction_content_item" v-for="(item,index) in dailyTransaction" :key="index" @click="daily_Tx_CLick(item)">
          <div class="deal_img">
            <img :src="item.img" alt="">
          </div>
          <div class="deal_count">
            <div id="deal_count">
              <span>成交{{item.count}}单</span>
            </div>
            <div class="deal_date">{{item.pay_time|moment}}</div>
          </div>
        </div>
      </div>
      <div class="pages">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="PageSize"
          layout="prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {apiDesktopIndex, apiGameList,apiProductTotal} from "@/request/API";

export default {
  name: 'dailyTransaction',
  props: {
  },
  components: {

  },
  data () {
    return {
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:0,
      // 默认每页显示的条数（可修改）
      PageSize:20,
      options: [],
      value: '',
      dailyTransaction:[]
    }
  },
  methods: {
    //item 点击事件
    daily_Tx_CLick(item){
      this.$router.push({
        path:'/home/dailyTxDetails',
        name:'DailyTxDetails',
        query:{
          game_id:this.value.value,
          time:item.pay_time,
        },
      })
    },
    //下拉框监听事件
    selectChange(event){
      this.currentPage = 1
      this.value = event
      this.getListData()
    },
    //分页监听事件
    handleCurrentChange(val) {
      this.currentPage = val
      this.getListData()
    },
    getSelectData(){
      apiGameList({
      }).then(res=>{
        res.data.forEach((item,index)=>{
          let options = new Object()
          options.value = item.game_id
          options.label = item.game_name
          if(index === 0){
            this.value = options
            //获取 每日成交列表
            this.selectChange(options)
          }
          this.options.push(options)
        })
      })
    },
    getListData(){
      apiProductTotal({
        game_id:this.value.value,
        page:this.currentPage
      }).then(res=>{
        this.dailyTransaction = res.data.list
        this.totalCount = res.data.total
        this.currentPage = res.data.page
      })
    }
  },
  mounted(){
    this.getSelectData()
  },
  watch: {
  },
  computed: {

  }
}
</script>

<style lang='less' scoped>
/deep/.Breadcrumb::before{
  width: 6px;
  height: 8px;
}
.daily_transaction{
  width: 1200px;
  .title{
    height: 80px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    a:first-of-type{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    a:last-of-type{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FCAD13;
      cursor: pointer;
      .Breadcrumb{
        font-size: 12px;
      }
    }
  }
  .list{
    width: 1150px;
    height: 810px;
    background: #FFFFFF;
    padding: 30px 20px 30px 30px;
    .gameSelect{
      /deep/.el-select{
        width: 198px;
        height: 38px;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #C0C4CC;
        line-height: 14px;
      }
      /deep/.el-input.is-focus .el-input__inner{
        border-color:#FCAD13 !important;
      }
      /deep/.el-select .el-input__inner:focus{
        border-color:#FCAD13 !important;
      }
      /deep/.el-select-dropdown__item.hover{
        background-color:#FFFBF3;
      }
      /deep/.el-select-dropdown__item.selected{
        color:#FCAD13;
      }
    }
    .content{
      margin: 21px 0 0 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .daily_transaction_content_item{
        height: 150px;
        width: 220px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        margin: 0 10px 21px 0;
        cursor: pointer;
        .deal_img{
          width: 220px;
          height: 100px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
          }
        }
        .deal_count{
          display: flex;
          flex-direction: row ;
          align-items: center;
          height: 50px;
          #deal_count{
            width: 123px;
            height: 29px;
            background: url("../../../assets/image/Home/dealCount.png") no-repeat ;
            background-size: cover;
            span{
              font-size: 16px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              margin-left: 18px;
              line-height: 28px;
            }
          }
          .deal_date{
            margin: 0 0 0 10px;
            color: #8F8F8F;
            font-family: PingFangSC-Thin, PingFang SC;
            font-size: 14px;
          }
        }
      }
    }
    .pages{
      margin: 29px 0 0 0;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
